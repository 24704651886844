* {
  font-weight: 400;
  font-size: 13px;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px;
}

.select2-container .select2-selection--single {
  height: 40px;
}

.dataTables_length {
  margin: 7px;
}

#search_div {
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
}

@media only screen and (max-width: 600px) {
  .close-btn {
    margin-top: -190px;
    float: right;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
    border: none;
  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.gredient {
  background-image: linear-gradient(to right, #0053af, #767679);
}

.btn-primary,
.bg-primary {
  background-color: #0053af !important;
  border-color: #0053af !important;
}

.text-active,
.text-primary {
  color: #0053af !important;
}

a {
  color: #0053af;
}

.card-primary:not(.card-outline)>.card-header {
  background-color: #0053af !important;
  border-color: #0053af !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #0053af;
}

.os-viewport {
  margin-bottom: 20px;
}

.nav-link {
  color: white !important;
}

.brand_color {
  color: #0053af;
}

.color2 {
  color: #141212 !important;
}

.nav-treeview {
  background-color: #767679 !important;
  border-radius: 3px !important;
}

.gradient {
  background-image: linear-gradient(to right, #0053af, #767679);
}

*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to top, #0053af, #767679);
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
  color: #dbcccc;
}

.nav-link:hover,
.nav-link:focus {
  color: #dbcccc;
}

.nav-link.active {
  background-color: #0053af !important;
  color: white !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #0053af !important;
}

.mpin {
  width: 43px;
  height: 43px;
  margin-left: 16px;
  padding-left: 15px;
  font-size: 25px;
  border: 1px solid #b39e9e !important;
  color: #594a4a;
  display: inline !important;
}

.nav-treeview {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.nav-treeview.open {
  max-height: 500px;
  /* Adjust the value based on your content */
  transition: max-height 0.3s ease;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
  float: right;
  margin-top: -5%;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
  height: 7.8vh;
}


@media (max-width: 991.98px) {

  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
    float: right;
    margin-top: -5%;
  }

  .navbar-expand .navbar-nav {
    flex-direction: row;
    /* height: 18vh; */
  }
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 700;
  /* width: max-content; */
  font-size: 15px;
}

.content-header h1 {
  font-weight: 400;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  pointer-events: auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

/* .fade {
  background: #767679;
  transition: 'opacity 0.5s linear',
} */

.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.fade.show {
  display: block;
}

/* Modal Content */
.modal-dialog {
  position: fixed;
  top: 5%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.log {
  display: flex;
  justify-content: center;
}

.sign {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.social {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.end {
  display: grid;
  justify-content: center;
  margin-top: 60px;
}

.navbar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 3px 8px 34px 5px lightgray;
}

.cont {
  background-color: lightgrey;
  width: 100%;
  overflow: hidden;
}

.imge {
  height: auto;
  max-width: 100%;
  border-radius: 50%;
}

.bg {
  background-color: aliceblue;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}

.page {
  background-color: white;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 90vh; */
  /* height: 70vh; */
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
}

.root {
  background-color: #ffd000;
  overflow: hidden;
  width: 100%;
  height: 50%;
}

.tab {
  width: 100%;
  height: 100vh;
  background-color: lightcyan;
  display: flex;
}

.submit {
  width: 20vh;
  height: 40px;
  margin-top: 20px;
  font-size: 20px;
  background-color: grey;
  border: 1px solid grey;
  color: white;
}

.slab_btn {
  border: none;
  background: none;
  color: green;
}

.slab_btn:hover {
  color: lightgreen;
  border: none;
}


.sidebar-collapsed .content-wrapper {
  margin-left: 0 !important;
  /* Change this value based on your design */
}

.custom-datepicker-input {
  width: 300px;
  /* Adjust the width as needed */
}



.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid transparent;
  border-image: linear-gradient(brown, #ff0000, #00ff00);
  border-image-slice: 1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.important-notice {
  width: 100%;
  display: table;
  min-height: 40px;
  background-color: #efefef;
  margin-bottom: 15px;
  position: relative;
  padding-right: 6px;
}

.important-notice .tag-area-top {
  top: 0;
  left: -13px;
  position: absolute;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 9px 20px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  clip-path: polygon(0% 0%, 86% 0%, 100% 50%, 86% 100%, 0% 100%);
  background-color: #0b2890d1 !important;
  z-index: 9;
}


marquee {
  font-size: 14px;
  font-weight: 300;
  font-family: inherit;
}

.important-notice marquee {
  margin-top: 11px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.panelHeading {
  padding: 10px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: blue;
  color: white;
  overflow: hidden;
}

.icon {
  background-color: whitesmoke;
  color: black;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}

.money {
  width: 30px;
  height: 50px;
  margin-left: 14%;
  display: flex;
  color: black;
}

.mt5 {
  display: flex;
  justify-content: flex-end;

}

.md-title {
  font-weight: 200;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
}



.cont {
  display: flex;
  justify-content: space-between;

}


.h4 {
  line-height: 24px;
  font-size: 15px;
}

/* .panel-body {
    padding: 20px;
  } */

:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}


.commission-setup {
  padding: 15px 15px;
  width: 100%;
}

.default-heading:first-child {
  margin-top: 0px;
}

.default-heading {
  font-size: 5px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 10px;
  color: #2f2f2f;
}


.commission-box .commission-amount {
  width: 30px;
  height: 20px;
  display: table;
  line-height: 1.3;
  position: relative;
  text-align: center;
  background-color: #fff;
  z-index: 2;
}

.commission-amount {
  float: right;
  width: 35px;
  text-align: right;
  white-space: nowrap;
}

.commission-box p.status.active {
  background-color: #61f16d;
}

.commission-box p.status,
.status-sec p.status {
  width: 8px;
  height: 8px;
  float: left;
  margin: 0px;
  margin-top: 5px;
  border-radius: 50%;
  position: relative;
  background-color: #d7242b;
  z-index: 1;
}

.default-heading {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 10px;
  color: #2f2f2f;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.modal-footer:after {
  display: table;
  content: " ";
}


/* .sidebar:hover {
  background-color: rgb(248, 231, 28);
  color: black;
  border-radius: 90px 90px 90px 90px;
  width: 90%;
} */

.wallet-icon {
  width: 21px;
  float: left;
  color: red;
  top: 6px;
  margin-right: 5px;
  position: relative;
}



.headerwrapper.collapsed .header-left {
  position: relative;
  left: -178px;
}

.headerwrapper .header-left .logo {
  margin-top: -3px;
  display: inline-block;

}


.logo-big {
  width: 120px;
  top: 6px;
  position: relative;
}

.logo-small {
  width: 48px;
  visibility: hidden;
}

.report-box {
  width: 100%;
  display: table;
  font-size: 12px;
  padding: 8px 0px;
  /* border-bottom: solid 1px #d2d2d2; */
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.commission-setup {
  padding: 15px 15px;
}

.commission-setup .default-heading:first-child {
  margin-top: 0px;
}

.default-heading {
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 10px;
  color: #2f2f2f;
}

.commission-setup .default-heading:first-child {
  margin-top: 0px;
}

.commission-setup .default-heading {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 10px;
  color: #2f2f2f;
}

.commission-box {
  width: 100%;
  display: table;
  font-size: 14px;
  position: relative;
  margin-bottom: 5px;
}

.commission-box:before {
  content: '';
  width: 100%;
  top: 9px;
  position: absolute;
  border-top: dashed 1px #bbbbbb;
  z-index: 1;
}

.modal-footer:after {
  display: table;
  content: " ";
}

.commission-box .slno {
  float: left;
}

.commission-box .slno,
.commission-box .commission-amount {
  width: 30px;
  height: 20px;
  display: table;
  line-height: 1.3;
  position: relative;
  text-align: center;
  background-color: #fff;
  z-index: 2;
}

.commission-box h4 {
  float: left;
  display: table;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.3;
  padding: 0px 10px 0px 0px;
  background: #fff;
  position: relative;
  z-index: 1;
}

h4,
.h4 {
  line-height: 24px;
}

h4 {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-family: inherit;
  color: inherit;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.modal-footer:after {
  clear: both;
}

.status-sec p.status.active {
  background-color: #61f16d;
}

.status-sec p.status {
  margin-right: 5px;

}

.status-sec li {
  float: left;
  margin-right: 15px;
  list-style-type: none;
}

.status-sec p.status.inactive {
  background-color: #e8696e;
}

.panel-body:after {
  clear: both;
  display: table;
  content: " ";

}

.angle-btn {
  display: inline-flex;
  margin-left: 30%;
  color: white;
  position: relative;
  background: none;
  border: none;
}


ul ul {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

ul {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/* .children li a {
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  display: block;
  text-decoration: none;
}

.nav .children li a:hover {
  background-color: transparent;
  color: black;
  transition: none;
}

.nav-pills>li+li {
  margin-left: 2px;
} */


.menu-devide {
  height: 1px;
  margin: 15px 0px;
  border-top: solid 1px #ffffff26;
}

.customer-support-sec {
  width: 94%;
  display: table;
  margin: 0 auto;
  text-align: center;
  padding: 20px 5px;
  margin-top: 6px;
  margin-bottom: 15px;
  margin-left: -4px;
  border-radius: 4px;
  background: #8f46e3;
  /* border-bottom: solid 1px #ffffff26; */
}

.customer-support-sec h3 {
  color: #baacf7;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

h3 {
  margin: 0px;
  padding: 0px;
  font-family: inherit;
}

.customer-support-sec p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 2px;
  margin: 0 0 10px;
}

.content {
  flex-grow: 1;
  margin-left: 1%;
}

.main-container {
  display: flex;
}


.services-search-box {
  position: relative;
}

.services-search {
  padding-left: 30px;
  /* Adjust the value as needed */
}

.services-search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.light-bg {
  background-color: #f5fff3;
}

.login-section {
  padding: 10px 25px 0px 25px;
}

.login-section .login-form {
  width: 430px;
  max-width: 100%;
  margin: 0 auto;
}

.white-bg {
  background-color: #ffffff !important;
}

.login-section .title {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  line-height: 18px;
  margin-bottom: 20px;
}

.title {
  position: relative;
  color: #1c2045;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

h2,
.h2 {
  font-size: 38px;
  font-style: normal;
  line-height: 46px;
  font-weight: 600;
  color: #1c2045;
  text-transform: capitalize;
  margin-bottom: 10px;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;

}

.text-center .title:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.help-block.with-errors {
  position: absolute;
  color: red;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
}

.captcha-input {
  width: 81px;
  height: 40px;
  float: left;
  display: table;
  border-radius: 4px;
  margin-right: 10px;
  background: rgb(6 171 5);
  background-position: 2px 12px;
  padding: 15px;
  font-style: italic;
  font-weight: bolder;
  letter-spacing: 2px;
}

.captcha-reload {
  float: left;
  margin: 18px 8px 0px 0px;
}

#topcaptcha {
  width: 250px !important;
}

.light-bg .form-control {
  background: #ffffff;
}

.form-control {
  height: 40px;
  border-radius: 5px;
  font-size: 13px;
  color: #111121;
  background: #ffffff;
  border: 1px solid #00000017;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

/* .form-check-input:checked[type=checkbox] {
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e);
} */

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

a,
button,
input {
  outline: medium none !important;
  color: #ff7810;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.a,
button,
input {
  outline: medium none !important;
  color: #ff7810;
  letter-spacing: 1px;
}

.forgot {
  text-decoration: none;
  color: #ff7810;
  letter-spacing: 1px;
  float: right;
  margin-top: -17px;
}

.log.log-theme {
  background: linear-gradient(90deg, rgb(222 59 0) 0%, rgb(222 59 0) 90%);
  color: #ffffff;
}

.log {
  padding: 12px 25px;
  font-weight: 500;
  /* background: none; */
  /* color: #111121; */
  border-radius: 5px;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.log span {
  transition: inherit;
  font-size: 20px;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
  font-size: 1.5rem;
}

.login-form h6 {
  font-size: 14px;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

h6,
.h6 {
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 10px;
  color: #1c2045;
}

h1,
h2,
h3,
h4,
h6 {
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
  box-sizing: border-box;
}

.customerSupp {
  font-size: 14px;
  margin-top: 10px;
}

.login-banner {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border: solid 7px #dde8da;
}

.blog-classic {
  border-bottom: 1px dashed #efefef;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.post {
  position: relative;
}

::selection {
  background: #ff7810;
  color: #fff;
  text-shadow: none;
}


@media (max-width: 768px) {
  /* #layout-wrapper {
    width: fit-content;
  } */

  .row {
    width: inherit;
  }

  .row2 {
    width: initial;
  }

  #body {
    background: cover;
  }

  .nav-item3 {
    overflow-x: hidden;
    overflow-y: visible;
    visibility: hidden;
  }

  .nav-item2 {
    margin-top: -20%;
  }

  .nav-item {
    visibility: visible;

  }


  .modal-content {
    pointer-events: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    outline: 0;
    margin-left: -30rem;
    margin-right: 30%;
    overflow-x: scroll;
  }

  .btn-primary {
    width: max-content;
    font-size: 8px;
    padding: 5px;
    float: right;
    margin: 5px;
  }

  .modal-footer {
    margin-right: 20%;
  }

  .main-footer {
    box-sizing: content-box;
    margin-top: 30%;
    width: 86%; 
  }

  .panel-heading {
    width: 70%;
  }

  /* .headerwrapper {
    height: 8vh;
  }

  .headerwrapper .menu-collapse {
   margin-right: 30%;
   float: left;
  }

  .headerwrapper .header-right .btn-group.main-balance .addmoney-but{
     font-size: 10px;
     font-style: italic;
     width: 40%;
     height: 37px;
     padding: 2px;
  }

  .headerwrapper .header-right .btn-group.main-balance {
    width: auto;
    margin-top: -20%;
    overflow: hidden;
    margin-right: 26%;
  }

  .headerwrapper .header-right .btn-group{
     float: right;
     margin-top: -18%;
     width: auto;
  }

  .panel {
    width: 70%;
  } */

}


.login-bg {
  background-size: cover;
  width: 100%;
  border-radius: 50px;
  /* height: 100%; */
}


.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #83de62;
  background: -webkit-linear-gradient(to bottom, #42e713, #1f4eb6);
  background: linear-gradient(to bottom, #42e713, #1f4eb6);
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.btn-google {
  color: #fff;
  background-color: #111;
  border-color: #000;
  padding: 15px 16px 5px 40px;
  position: relative;
  font-family: fontfutura;
  font-weight: 600;
}

.btn-google:after {
  content: "GET IT ON";
  position: absolute;
  top: 5px;
  left: 40px;
  font-size: 10px;
  font-weight: 400;
}

.btn-google:before {
  content: "";
  background-image: url(https://4.bp.blogspot.com/-52U3eP2JDM4/WSkIT1vbUxI/AAAAAAAArQA/iF1BeARv2To-2FGQU7V6UbNPivuv_lccACLcB/s30/nexus2cee_ic_launcher_play_store_new-1.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -15px;
}

.facebookBtn {
  background: #4060A5;
}

.smGlobalBtn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 2px #999;
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  line-height: 2em;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}


.linkedinBtn {
  background: #0094BC;
}

.pinterestBtn {
  background: #cb2027;
}

.tumblrBtn {
  background: #3a5876;
}

.youtubeBtn {
  background: #ED0A0A;
}

.custom-date-picker {
  width: 280px;
  height: 5.2vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
}

.custom-dob-picker{
  width: 300px;
  height: 5.2vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
}

.txn-date-picker{
  width: 170px;
  height: 5.2vh;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;

}

.custom-date-picker:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.invisible {
  display: none;
}

.visible {
  display: block;
}

.zoomed {
  transform: scale(2); 
  transition: transform 0.3s ease; 
  margin-left: 10%;
}

.zoomed2 {
  transform: scale(2); 
  transition: transform 0.3s ease; 
}

.serviceTxnCard {
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  background-color: #ebd5d5;
  display: flex;
  max-width: 332px;
  flex-direction: column;
  white-space: nowrap;
  padding: 16px;
}
.txnHeading {
  color: #000;
  align-self: center;
  font: 700 15px Inter, sans-serif;
}
.txnStatus {
  display: block;
  margin-top: 24px;
  gap: 0px;
  font-size: 12px;
  font-weight: 600;
}
.txnStatus2 {
  display: flex;
  gap: 8px;
  color: #0e543b;
  flex: 1;
  padding: 8px;
}
.txnStatus3{
  font-family: Inter, sans-serif;
  font-weight: bold;
}
.txnStatus4 {
  font-family: Inter, sans-serif;
  font-weight: bold;

}
.txnStatus5 {
  display: flex;
  gap: 8px;
  color: #f5501f;
  flex: 1;
  padding: 8px;

}
.txnStatus6 {
  font-family: Inter, sans-serif;
}
.txnStatus7 {
  font-family: Inter, sans-serif;
}
.txnStatus8 {
  display: flex;
  gap: 8px;
  color: #f50f00;
  flex: 1;
  padding: 8px;
}
.txnStatus9 {
  font-family: Inter, sans-serif;
}
.txnStatus10 {
  font-family: Inter, sans-serif;
}


@media print {
  /* Add your print-specific styles here */
  body * {
      visibility: hidden;
  }
  #receipt {
      visibility: visible;
  }
  #receipt * {
      visibility: visible;
  }
}

@media print {
    #receipt {
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
}